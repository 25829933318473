import { useEffect } from "react";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { Tooltip } from "react-tooltip";

import {
  	addAnnotationId
} from "@utils/map/annotation/annotation.interaction";
import SidebarTemplate from "./sidebarElements/SidebarTemplate";
import useKeyboardShortcut from "@hooks/useKeyboardShortcut.hook";
import { KeyboardShortcut } from "../sharedStyles.component";

import {useProject, ProjectMode} from "@contexts/Project.context";

const FilterTab = styled.div`
	width: 100%;
	text-align: left;
	background-color: ${(props) => props.theme.colors.modal};
	padding: 20px;
`;

const LabelText = styled.span`
	font-weight: 600;
`;

const StyledCheckbox = styled(Form.Check)`
	.form-check-input{
		border: 2px solid ${(props) => props.color};
		&:checked {
			background-color: ${(props) => props.color};
		}
	}
`;

/**
 * Renders the sidebar that is responsible with altering the presentation of the map.
 * anything like changing the styles, showing/hiding layers, filtering etc. should be done here.
 */
const AnnotationSidebar = () => {

	const { annotationSidebarData: sidebarData, colorOptions, projectMode, dispatch } = useProject();
	const {classIds, labels, activeClassId} = sidebarData || {classIds: [], labels: []};

	const doAddAnnotationId = (classId) => {
		if(projectMode === ProjectMode.ORTHOPHOTO) {
			addAnnotationId(classId);
		}
	}

	const handleClick = (classId) => {
		doAddAnnotationId(classId);
		dispatch({
			type: 'setAnnotationSidebarData',
			payload:{
				classIds,
				labels,
				activeClassId: classId,
			},
		})
	};

	const Label = ({text, keyboardShortcutText}) => {
		return (
			<span
				data-tooltip-id="annotate-category-tip"
				data-tooltip-content={`Shortcut key: ${keyboardShortcutText}`}
				data-tooltip-place="top"
			>
				<LabelText>{text}</LabelText> <KeyboardShortcut>{keyboardShortcutText}</KeyboardShortcut>
			</span>
		);
	}

	// runs handleclick on the first radio button when the component is rendered
	useEffect(() => {
		if(classIds.length > 0){
			doAddAnnotationId(classIds[0]);
		}
	}, [classIds]);

	return (
		<SidebarTemplate
			header="Items to annotate"
			zindex={1}
			sidebarId="annotationSidebar"
			closeable={false}
			hideHeaderName={true}
		>
			<FilterTab>
				<Form>
					{classIds.map((classId, index) => {
						return (
							<Radio
								key={classId}
								type="radio"
								classId={classId}
								label={<Label text={labels[index]} keyboardShortcutText={classId+1} />}
								name="annotation"
								id={`annotate-radio-${classId}`}
								handleClick={handleClick}
								checked={activeClassId === classId}
								color={colorOptions[classId]}
							/>
						);
					})}
					<Tooltip id="annotate-category-tip" variant="light" />
				</Form>
			</FilterTab>
		</SidebarTemplate>
	);
};
export default AnnotationSidebar;

const Radio = ({classId, handleClick, color, ...props}) => {
	// Keyboard shortcuts for radio buttons
	useKeyboardShortcut((classId + 1), true, () => {handleClick(classId)});

	const {r, g, b} = color;

	return (
		<StyledCheckbox
			onChange={() => {
				handleClick(classId);
			}}
			color={`rgb(${r}, ${g}, ${b})`}
			{...props}
		/>
	);

}
