import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import { useProject } from '@contexts/Project.context';
import { convertToRgba } from '@utils/map/helpers';

// import FilterSinglePhotos from './modifyLayers/FilterSinglePhotos'; 'Someone' implemented this in to main too early. Uncommenting for now.

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Range } from 'react-range';
import { Tooltip } from 'react-tooltip';
import { debounce } from 'lodash';

const Container = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 1;
	bottom: 36px;
	left: 0;
	width: 100%;
	background-color: ${props => props.theme.colors.background};
	padding-top: 5px;

	transition: all 0.3s ease-in-out;
	transform: translateY(40%);
	opacity: 0.8;

	&:hover,
	&.dragging {
		transform: translateY(0);
		opacity: 1;
	}
`;

const CarouselContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	min-height: 85px;
	overflow: hidden;
	background-color: ${props => props.theme.colors.background};
`;

const CarouselItems = styled.div`
	width: 100%;
	margin-top: 0px;
`;

const CarouselWrapper = styled.div`
	width: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 30px;
	padding-right: 30px;
	background-color: transparent;
	margin-bottom: 10px;
`;

const CarouselItem = styled.div`
	max-width: 20rem;
	height: 100%;
	padding: 0 10px 10px;
	position: relative;

	&:focus {
		outline: none;
	}
	&:hover {
		cursor: pointer;
		img {
			transform: scale(1.1);
		}
	}

	img {
		max-width: 100%;
		max-height: 100px;
		object-fit: contain;

		border-style: ${props => (props.isSelected ? 'solid' : 'none')};
		border-color: yellow;
		transform: scale(${props => (props.isSelected ? '1.15' : '1')});

		transition: all 0.1s ease-in-out;
	}
`;

const CarouselSlider = () => {
	const { singleImageFeatures, filteredImages } = useProject();

	let sliderRef = useRef(null);
	const [slideIndex, setSlideIndex] = useState([0]);
	const [slidesToShow, setSlidesToShow] = useState(0);
	const [isDragging, setIsDragging] = useState(false);

	const hasDetections = singleImageFeatures?.some(
		item => item.get('detections')?.classes.length > 0
	);

	// Slides to show can not be more items.length because of bug in slick slider
	const slidesToShowLarge = Math.min(20, filteredImages?.length);
	const slidesToShowMedium = Math.min(10, filteredImages?.length);
	const slidesToShowSmall = Math.min(5, filteredImages?.length);

	const settings = {
		infinite: false,
		slidesToShow: slidesToShowLarge,
		slidesToScroll: slidesToShowLarge,
		centerPadding: '60px',
		initialSlide: 0,
		speed: 500,
		responsive: [
			{
				breakpoint: 2000,
				settings: {
					slidesToShow: slidesToShowMedium,
					slidesToScroll: slidesToShowMedium,
					beforeChange: (current, next) => {
						setSlideIndex([next]);
						setSlidesToShow(slidesToShowMedium);
					},
				},
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: slidesToShowSmall,
					slidesToScroll: slidesToShowSmall,
					beforeChange: (current, next) => {
						setSlideIndex([next]);
						setSlidesToShow(slidesToShowSmall);
					},
				},
			},
		],
		beforeChange: (current, next) => {
			setSlideIndex([next]);
			setSlidesToShow(slidesToShowLarge);
		},
	};

	const handleMouseDown = () => setIsDragging(true);

	useEffect(() => {
		const handleMouseUp = () => setIsDragging(false);

		document.addEventListener('mouseup', handleMouseUp);

		return () => {
			document.removeEventListener('mouseup', handleMouseUp);
		};
	}, []);

	const handleIndexSliderChange = debounce(index => {
		setSlideIndex(index);
		sliderRef.current.slickGoTo(index);
	}, 100);

	if (!singleImageFeatures?.length) {
		return null;
	}

	return (
		<>
			<Container className={isDragging ? 'dragging' : ''}>
				{/* {hasDetections && <FilterSinglePhotos />} 'Someone' implemented this in to main too early. Uncommenting for now. */}
				<CarouselContainer>
					<CarouselWrapper>
						<CarouselItems>
							{filteredImages?.length > 0 && (
								<Slider
									ref={slider => {
										sliderRef.current = slider;
									}}
									{...settings}>
									{filteredImages?.map(item => (
										<SliderImage
											key={item.get('name')}
											item={item}
											sliderRef={sliderRef}
										/>
									))}
								</Slider>
							)}
						</CarouselItems>
						<Tooltip id="index-slider" variant="dark" zindex={50} />
						<Range
							onChange={handleIndexSliderChange}
							min={0}
							max={filteredImages.length - slidesToShow}
							step={1}
							values={slideIndex}
							renderTrack={({ props, children }) => (
								<div
									{...props}
									style={{
										...props.style,
										height: '6px',
										backgroundColor: '#8c8b8b',
									}}>
									{children}
								</div>
							)}
							renderThumb={({ props }) => (
								<div
									{...props}
									onMouseDown={handleMouseDown}
									style={{
										...props.style,
										height: '16px',
										width: '16px',
										borderRadius: '50%',
										backgroundColor: '#198754',
										boxShadow: '0px 2px 6px #1c1c1c',
									}}
									data-tooltip-id="index-slider"
									data-tooltip-content={slideIndex}
									data-tooltip-place="top"
								/>
							)}
						/>
					</CarouselWrapper>
				</CarouselContainer>
			</Container>
		</>
	);
};

export default CarouselSlider;

const SliderImage = ({ item, sliderRef }) => {
	const {
		filteredImages,
		selectedSingleImage,
		mapObject,
		colorScheme,
		dispatch,
	} = useProject();

	const [isSelected, setIsSelected] = useState(false);

	const handleImageClick = event => {
		event.preventDefault();
		dispatch({ type: 'setSelectedSingleImage', payload: item });
	};

	const handleMapClick = () => {
		dispatch({ type: 'setSelectedSingleImage', payload: null });
		setIsSelected(false);
	};

	useEffect(() => {
		// For when a selectedImage can be set from outside the component

		if (!selectedSingleImage) {
			// If no image is selected, set isSelected to false and exit the function
			if (isSelected) {
				setIsSelected(false);
			}

			return;
		}

		// Get the name of the selected image
		const selectedImageName = selectedSingleImage.get('name');
		// Get the name of the current item
		const itemName = item.get('name');

		if (selectedImageName !== itemName) {
			// If the selected image name does not match the current item name, set isSelected to false and exit the function
			if (isSelected) {
				setIsSelected(false);
			}

			return;
		}

		// If the selected image name matches the current item name, set isSelected to true
		setIsSelected(true);

		// Find the index of the selected image in the items array
		const sliderIndex = filteredImages.findIndex(
			slide => slide.get('name') === selectedImageName
		);

		// Navigate to the corresponding slider index
		sliderRef.current.slickGoTo(sliderIndex);

		mapObject.on('click', handleMapClick);

		return () => {
			mapObject.un('click', handleMapClick);
		};
	}, [selectedSingleImage]);

	return (
		<CarouselItem onClick={handleImageClick} isSelected={isSelected}>
			<DetectionHits
				detections={item.get('detections')}
				colorScheme={colorScheme}
			/>
			<img src={item.get('thumbnail')} alt={item.get('name')} />
		</CarouselItem>
	);
};

const ClassesContainer = styled.div`
	position: absolute;
	top: 10px;
	left: 14px;
	width: 80%;
	height: 80%;
	z-index: 1;
	display: flex;
	flex-wrap: nowrap;
	font-size: 0.7rem;
	gap: 10px;
	color: white;
	text-shadow: 1px 1px 1px black;
`;
const ColorCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${props => props.color};
	display: inline-block;
	margin-right: 2px;
`;
const DetectionHits = ({ detections, colorScheme }) => {
	if (!detections) return null;

	return (
		<ClassesContainer>
			{detections.classes.map((detection, index) => (
				<ClassItem
					key={index}
					detection={detection}
					color={colorScheme[detection.id]?.color}
				/>
			))}
		</ClassesContainer>
	);
};

const ClassItem = ({ detection, color }) => (
	<div>
		<ColorCircle color={convertToRgba(color)} />
		{detection.count}
	</div>
);
