// React imports
import { useState } from 'react';

// Third-party library imports
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Button } from 'react-bootstrap';
import { VscRecord, VscChromeMaximize } from 'react-icons/vsc';
import { BiShapePolygon, BiShapeTriangle } from 'react-icons/bi';
import { BsFillEraserFill } from 'react-icons/bs';

// Contexts
import { useProject } from '@contexts/Project.context';

// Hooks
import useKeyboardShortcut from '@hooks/useKeyboardShortcut.hook';

// Utils
import { startErasor, stopErasor } from '@utils/map/erasor.interaction';
import { KeyboardShortcut } from '@components/map/sharedStyles.component';

// Components
import UtilityButton from './UtilityButton';

const DrawingIcon = ({ toolType }) => {
	switch (toolType) {
		case 'Box':
			return <VscChromeMaximize />;
		case 'Circle':
			return <VscRecord />;
		case 'Freehand':
			return <BiShapePolygon />;
		case 'Polygon':
			return <BiShapeTriangle />;
		default:
			return null;
	}
};

const AnnotationDrawTools = () => {
	const {
		mapObject,
		annotationType,
		annotationMode,
		annotationDrawTool,
		availableAnnotationDrawTools,
		dispatch,
	} = useProject();

	const drawToolOptions = availableAnnotationDrawTools[annotationType]?.map(
		tool => {
			return {
				label: tool,
				value: tool,
				active: tool === annotationDrawTool,
				icon: () => <DrawingIcon toolType={tool} />,
			};
		}
	);

	const [drawing, setDrawing] = useState(true);

	// Start drawing
	const startDraw = () => {
		if (drawing) return;

		setDrawing(true);
		stopErasor(mapObject);
	};

	// Start erasing
	const startErase = () => {
		if (!drawing) return;

		setDrawing(false);
		startErasor(mapObject);
	};

	const changeDrawTool = tool => {
		console.log('Changing draw tool to', tool.value);
		dispatch({ type: 'setAnnotationDrawTool', payload: tool.value });

		if (!drawing) {
			startDraw();
		}
	};

	useKeyboardShortcut('D', !!annotationMode, () => startDraw());
	useKeyboardShortcut('E', !!annotationMode, () => startErase());

	return (
		<div>
			<ButtonGroup className="me-3">
				{drawToolOptions.map((tool, index) => (
					<UtilityButton
						key={`tool-${index}`}
						label={tool.label}
						tooltip={{
							id: 'annotate-tip',
							content: `${tool.label} tool`,
							place: 'top',
						}}
						onClick={() => changeDrawTool(tool)}
						variant={tool.active && drawing ? 'success' : 'dark'}
						icon={tool.icon}
					/>
				))}
				<Button
					onClick={startDraw}
					variant="dark"
					className="p-1 pe-2"
					data-tooltip-id="annotate-tip"
					data-tooltip-content="Keyboard shortcut 'D' to draw"
					data-tooltip-place="top">
					<KeyboardShortcut>D</KeyboardShortcut>
				</Button>
			</ButtonGroup>

			<UtilityButton
				label="Erase"
				tooltip={{
					id: 'annotate-tip',
					content: 'Erase annotations from the current square',
					place: 'top',
				}}
				onClick={startErase}
				variant={!drawing ? 'success' : 'dark'}
				keyboardShortcutLabel="E"
				icon={() => (
					<BsFillEraserFill
						style={{ marginTop: '-4px', marginRight: '5px' }}
					/>
				)}
			/>
		</div>
	);
};
export default AnnotationDrawTools;
