import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { unByKey } from 'ol/Observable';

import Checkbox from '../sidebars/sidebarElements/checkbox.component';
//import SelectedPhotoLayer from './SelectedPhotoLayer'; 'Someone' implemented this in to main too early. Uncommenting for now.

import { getUnrotatedGrid, getSingleImage, getMasterFeatures } from '@api';
import { useProject } from '@contexts/Project.context';
import { deleteLayerByCustomId } from '@utils/map/helpers';

const SinglePhotoLayer = ({ maxZoomLevel = 23 }) => {
	const {
		mapObject,
		defaultProjection,
		project,
		pickedTask,
		mapTooltip,
		colorSchema,
		dispatch,
	} = useProject();

	const { tooltip, tooltipRef } = mapTooltip;

	const adding = useRef(false);
	const pointermovePopupKey = useRef(null);

	const [singlePhotosLayer, setSinglePhotosLayer] = useState(null);
	const singlePhotoLayerId = 'singlePhotosLayer';

	const { data, isError: dataLoadError, error } = useQuery({
		queryKey: ['grid_tiles', project.uuid],
		queryFn: () => getUnrotatedGrid(project.uuid),
		enabled: !!project?.uuid && project?.image_mode === 'single_image',
		refetchOnWindowFocus: false,
		retry: false,
	});

	const {
		data: detectionsData,
	} = useQuery({
		queryKey: ['single_photo_detections', project.uuid, pickedTask?.model_uuid],
		queryFn: () =>
			getMasterFeatures(project.uuid, pickedTask.model_uuid, 'normalized_predictions'),
		enabled: !!data?.features && !!pickedTask?.model_uuid,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const setTooltipContent = (feature) => {
		// Make mouse cursor a pointer
		mapObject.getTargetElement().style.cursor = 'pointer';

		const thumb = feature.get('thumbnail');
		if (!thumb) return;

		const coordinates = feature.getGeometry().getCoordinates();
		tooltip.setPosition(coordinates);

		const _rotation = feature.get('rotation');

		dispatch({payload: (
			<TooltipComponent
				imageUrl={thumb}
				rotation={_rotation}
				detectionClasses={feature.get('detections')?.classes || []}
			/>
		), type: 'setMapTooltipContent'});

		Object.assign(tooltipRef.style, {
			padding: '0',
			minWidth: '250px',
			minHeight: '150px',
			display: 'block',
			position: 'relative',
		});

	};

	const pointerMoveHandler = useCallback(
		(event) => {
			if(!mapObject) return;

			const feature = mapObject.forEachFeatureAtPixel(
				event.pixel,
				function (feature) {
					return feature;
				}
			);

			if (feature) {
				setTooltipContent(feature);
			} else {
				// Make mouse cursor a pointer
				mapObject.getTargetElement().style.cursor = 'default';
				tooltip.setPosition(undefined);
				tooltipRef.style = '';
			}
		},
		[tooltip, tooltipRef, colorSchema]
	);

	const updateSinglePhotoLayer = useCallback(async () => {
		if (data?.features != null && !adding.current) {
			adding.current = true;

			// Create a vector source that contains your features
			const singlePhotoSource = new VectorSource({
				features: [],
			});

			// Create a vector layer that contains the vector source
			const singlePhotosLayer = new VectorLayer({
				source: singlePhotoSource,
				visible: true,
				defaultProjection: defaultProjection,
				zIndex: 24,
				maxZoom: maxZoomLevel,
				properties: {
					customLayerId: singlePhotoLayerId,
				},
				style: (feature) => {
					const isDetect = !!feature.get('detections');
					return new Style({
						image: new CircleStyle({
							radius: 5,
							fill: new Fill({ color: isDetect ? 'green' : 'yellow' }),
							stroke: new Stroke({ color: 'black', width: 2 }),
						}),
						zIndex: 14,
					});
				},
			});

			setSinglePhotosLayer(singlePhotosLayer);
			mapObject.addLayer(singlePhotosLayer);

			const featurePromises = data.features.map(
				async (feature, index) => {
					const image_name = feature.properties.tile_name;
					let image,
						thumbnail = null;
					try {
						image = await getSingleImage(`filelink?key=${project.uuid}/images/${image_name}`);
						thumbnail = await getSingleImage(
							`filelink?key=${project.uuid}/images/thumbnails/${image_name}`
						);

						if (!thumbnail) return;
					} catch (e) {
						console.error('Could not get images from s3', e);
						return;
					}

					const _feature = new Feature({
						index: index,
						geometry: new Point(
							fromLonLat([
								feature.properties.center_longitude,
								feature.properties.center_latitude,
							])
						),
						image: image,
						thumbnail: thumbnail,
						polygons: feature.geometry.coordinates[0],
						name: feature.properties.tile_name,
						rotation: feature.properties.gimbal_yaw_degrees,
					});
					singlePhotoSource.addFeature(_feature);
				}
			);

			Promise.all(featurePromises).then(() => {
				const singleImageFeatures = singlePhotoSource.getFeatures();

				// If no features were added, console warn
				if (singleImageFeatures.length === 0) {
					//@TODO: Show a message to the user
					console.warn(
						'No features were added to the single photo layer'
					);
				} else {
					dispatch({
						type: 'setSingleImageFeatures',
						payload: singleImageFeatures,
					});
					dispatch({
						type: 'setFilteredImages',
						payload: singleImageFeatures,
					});
				}
			});

			// Add pointermove event listener
			const pointermoveKey = mapObject.on(
				'pointermove',
				pointerMoveHandler
			);
			pointermovePopupKey.current = pointermoveKey;

			adding.current = false;
		}
	}, [data]);

	useEffect(() => {
		updateSinglePhotoLayer();

		if (dataLoadError) {
			console.warn('could not fetch single photo', error);
			if (mapObject && singlePhotosLayer) {
				deleteLayerByCustomId(mapObject, singlePhotoLayerId);
				setSinglePhotosLayer(null);
			}
		}
	}, [updateSinglePhotoLayer, dataLoadError]);

	useEffect(() => {
		const singleImageFeatures = singlePhotosLayer?.getSource()?.getFeatures();

		// Remove any existing detections from the features
		singleImageFeatures?.forEach(feature => {
			feature.set('detections', null);
		});

		if(!detectionsData) return;

		// If any detections, add them to the corresponding features
		if(detectionsData?.features) {
			singleImageFeatures.forEach(feature => {
				const filename = feature.get('name');
				const detections = detectionsData.features.filter(
					detection => detection.properties.filename === filename
				);
				if(detections.length > 0){
					const classes = detections.reduce((acc, detection) => {
						const { classname: name, classid: id } = detection.properties;
						const existingClass = acc.find(item => item.name === name && item.id === id);

						if (existingClass) {
						  existingClass.count += 1;
						} else {
						  acc.push({ name, id, count: 1 });
						}

						return acc;
					}, []);

					feature.set('detections', {
						features: detections,
						classes
					});
				}
			});
		}
	}, [detectionsData]);

	useEffect(() => {
		return () => {
			// Clean up on unmount

			console.log('Cleaning up single photo layer');

			tooltipRef.style = '';
			tooltipRef.innerHTML = '';
			dispatch({ type: 'setMapTooltipContent', payload: null });

			if (pointermovePopupKey.current) {
				unByKey(pointermovePopupKey.current);
				pointermovePopupKey.current = null;
			}

			deleteLayerByCustomId(mapObject, singlePhotoLayerId);
			setSinglePhotosLayer(null);

		};
	}, []);


	if (!singlePhotosLayer) return null;

	return (
		<div>
			<Checkbox
				label="Single Image"
				canEdit={false}
				defaultState={true}
				handleCheck={() => singlePhotosLayer.setVisible(true)}
				handleUncheck={() => singlePhotosLayer.setVisible(false)}
			/>
			{/* <SelectedPhotoLayer singlePhotosLayer={singlePhotosLayer} /> // 'Someone' implemented this in to main too early. Uncommenting for now. */}
		</div>
	);
};

export default SinglePhotoLayer;

const StyledImage = styled.img`
	max-width: 250px;
	max-height: 250px;
	transform: rotate(${props => props.rotation}deg);
`;
const ClassItems = styled.div`
	padding: 5px 10px;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 10px;
    font-size: 0.9rem;
	color: white;
    text-shadow: 1px 1px 1px black;
`;
const ColorDot = styled.span`
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 2px;
	background-color: rgba(${props => props.r}, ${props => props.g}, ${props => props.b}, 1);
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
`;
const TooltipComponent = ({imageUrl, rotation, detectionClasses}) => {
	const {colorScheme} = useProject();
	const rotationDeg = rotation > 180 ? '0' : '180';

	return (
		<div>
			<StyledImage src={imageUrl} rotation={rotationDeg} />
			<ClassItems>
				{detectionClasses?.map(item => {
					const { r, g, b } = colorScheme[item.id]?.color || { r: 0, g: 0, b: 0, a: 0 };
					return (
					<div key={item.id}>
						<ColorDot r={r} g={g} b={b} />
						{item.count}
					</div>
					);
				})}
			</ClassItems>
		</div>
	);
};
