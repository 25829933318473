import { useRef, useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { VscRecord } from "react-icons/vsc";
import { BiShapePolygon } from "react-icons/bi";

import { useProject, AnnotationTypes } from '@contexts/Project.context';
import { createTask } from '@utils/api';
import { cleanUserInput } from '@utils/helpers';
import { StyledModal } from './sharedModalStyles';

import Input from '../form/Input';
import Toggle from '../form/toggle.component';
import AdvancedSelect from '../form/advancedSelect';
import Alert from '../alert/Alert';
import { useToast } from '@contexts/Toast.context';

const TaskModal = ({ setShowModal, refetchTasks, pickTask }) => {

	const { project, tasks } = useProject();
	const { addToast } = useToast();
	const [items, setItems] = useState([]); // user input
	const [warning, setWarning] = useState(null);
	const [existingTaskWarning, setExistingTaskWarning] = useState(null);

	const nameInput = useRef(null);
	const formData = useRef(null);
	const addingTask = useRef(false);

	// Merge all arrays inside tasks.classes into one array
	const existingTasks = tasks
		.map((task) => task.classes)
		.reduce((acc, val) => acc.concat(val), []);

	const mutation = useMutation({
        mutationFn: (newTask) => {
        	return createTask(newTask)
        },
		onSuccess: async (res) => {
			await refetchTasks();
			pickTask(res);
			setItems([]);
			addToast({
				id: `create_custom_model-${new Date().getTime()}`,
				title: `Model "${res.description}" created and selected`,
			});
			setShowModal(false);
		},
		onError: (error) => {
			console.log(error);
			setWarning({
				variant: 'danger',
				text: error?.response?.data?.message ?? error?.message
			});
		}
    })

	const addCustomTask = () => {
		addingTask.current = true;

		setWarning(null);
		const taskNameInput = cleanUserInput(
			formData.current.elements.taskName.value
		);
		const annotationType =  formData.current.elements.annotationType.value;

		if (items.length === 0) {
			setWarning({
				variant: 'warning',
				text: 'Please enter at least one item to detect'
			});
		} else if (taskNameInput === '') {
			setWarning({
				variant: 'warning',
				text: 'Please enter a name for your task'
			});
		} else if (annotationType === '') {
			setWarning({
				variant: 'warning',
				text: 'Please select an annotation type'
			});
		} else {
			const newTask = {
				project_uuid: project?.uuid,
				classes: items,
				description: taskNameInput,
				isSegmentation: annotationType === AnnotationTypes.SEGMENTATION,
			};
			mutation.mutate(newTask);
		}

		addingTask.current = false;
	};

	useEffect(() => {
		if (nameInput.current) {
			nameInput.current.focus();
		}
	} ,[]);

	return (
		<StyledModal
			show={true}
			onHide={() => setShowModal(false)}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>Creating a Custom Model</Modal.Title>
			</Modal.Header>

			<Form ref={formData} onSubmit={e => {
					e.preventDefault();
					addCustomTask();
				}}>
				<Modal.Body>
					<Alert variant={warning?.variant}>
						{warning?.text}
					</Alert>
					<Input
						label="Model Name"
						name="taskName"
						type="text"
						placeholder="Coniferous Trees"
						autoFocus
						required
						ref={nameInput}
					/>

					<hr/>

					<Form.Group required className="mb-4 mt-4">
						<Form.Label>Annotation type</Form.Label>
						<Toggle
							name="annotationType"
							type="radio"
							label={<>Object Detection <VscRecord aria-hidden style={{transform: 'translateY(-2px)'}} /></>}
							value={AnnotationTypes.OBJECT_DETECTION}
							helperText="For example, used to detect and count trees"
							id="circle-annotation-type-checkbox"
						/>

						<Toggle
							name="annotationType"
							type="radio"
							label={<>Segmentation <BiShapePolygon aria-hidden /></>}
							value={AnnotationTypes.SEGMENTATION}
							helperText="For example, used to identifying areas with windfall"
							id="polygon-annotation-type-checkbox"
						/>
					</Form.Group>

					<hr/>

					<AdvancedSelect
						id="itemsToDetect"
						label="Item(s) to detect"
						placeholder="Type to add items..."
						helperText="Specify the object(s) you want to train the model to detect, e.g., coniferous trees."
						formatCreateLabel={inputValue =>
							`Click to add new item "${inputValue}"`
						}
						onChange={values => {

							const matchingTasks = values.filter(value => existingTasks.includes(cleanUserInput(value.label)));
							if (matchingTasks.length > 0) {
								setExistingTaskWarning(matchingTasks.map(task => task.label).join(', '));
							} else {
								setExistingTaskWarning(null);
							}

							setItems([
								...new Set(
									values.map(item =>
										cleanUserInput(item.label)
									)
								),
							]);
						}}
						value={items.map((item, index) => {
							return {
								value: `${item}-${index}`,
								label: item,
							};
						})}
						openMenuOnClick={false}
						openMenuOnFocus={false}
						isMulti
						creatable
						required
					/>

					{existingTaskWarning && (
						<Alert variant="warning">
							<p className="small">
								Oops! It seems like one or more items you're trying to add already exist in another model:<br/>
								<strong>{existingTaskWarning}</strong>
							</p>
							<p className="small mb-0">
								To avoid any confusion, it would be helpful to give these items more specific names that match exactly what you're trying to teach the model. Feel free to choose names that best suit your training needs.
							</p>
						</Alert>
					)}

				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => {
							setItems([]);
							setShowModal(false);
						}}>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="success"
						disabled={mutation.isLoading || addingTask.current}
					>
						{mutation.isLoading || addingTask.current ? 'Loading...' : 'Add Model'}
					</Button>
				</Modal.Footer>
			</Form>
		</StyledModal>
	);
};

export default TaskModal;
