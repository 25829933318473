import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { Tooltip } from 'react-tooltip';

import { FaLock } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { FiHelpCircle } from 'react-icons/fi';
import { VscRecord } from 'react-icons/vsc';
import { BiShapePolygon } from 'react-icons/bi';
import { GrMultiple } from 'react-icons/gr';

import NewModel from '../utilityButtons/newModel.component';
import Toolbar from '@components/toolbar/toolbar.component';
import TaskModal from '@components/modal/TaskModal';

import { useProject } from '@contexts/Project.context';
import { useAuth } from '@contexts/User.context';
import { useToast } from '@contexts/Toast.context';
import useSetSearchParams from '@hooks/useSetSearchParams.hook';
import {
	getAvailableModels,
	getDemoModel,
	deleteMultiModel,
	getModelsDetectedOn,
	deleteModel,
} from '@api';
import Input from '@components/form/Input';

/**
 * The toolbar in the map editor page. It contains all functionality to interact with the map and the annotations on it on it.
 */

const DropMenu = styled(Dropdown.Menu)`
	max-height: 55vh;
	min-width: 450px;
	overflow-y: overlay;
	padding-top: 0;
	padding-bottom: 0;
`;
const DropInner = styled.div`
	position: relative;
`;
const SearchInput = styled.div`
	position: sticky;
	left: 0;
	bottom: 0;
	margin-top: 10px;
	> div {
		margin: 0;
	}
`;
const DropdownHeader = styled(Dropdown.Header)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5em;
	width: 100%;
	padding-left: 35px;
	span {
		display: inline-block;
		line-height: 1;
	}
`;
const DropDownButton = styled.button`
	padding: 0;
	margin: 0;
	border: none;
	background: none;
	font-style: italic;
	&:hover {
		background: none;
	}
`;

const Row = styled.i`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const FilterLabel = styled(Form.Label)`
	color: ${props => props.theme.colors.textColor};
	opacity: 0.9;
	font-size: 1rem;
	font-weight: 600;
	text-transform: capitalize;
	position: relative;
	top: 7px;
	margin-right: 10px;
`;

const TaskPicker = styled(Form)`
	display: flex;
	flex-direction: row;
	margin-right: 20px;
	flex-grow: 1;
	margin-top: 2px;
	align-items: center;
`;

const DropText = styled.i`
	opacity: 0.8;
	padding-right: 10px;
`;

const DeleteIcon = styled(MdDelete)`
	transform: scale(1.2);
	transition: 0.2s;
	button:not(:disabled) & {
		&:hover {
			cursor: pointer;
			color: ${props => props.theme.colors.warning};
		}
	}
`;

const LockIcon = styled(FaLock)`
	transform: scale(0.9);
	opacity: 0.3;
`;

const commonIconStyles = css`
	transform: scale(0.9);
	opacity: 0.3;
	margin-right: 8px;
	margin-left: -6px;
	path {
		stroke: currentColor;
	}
`;
const SegmentationIcon = styled(BiShapePolygon)`
	${commonIconStyles}
	margin-top: -4px;
`;
const ObjectDetectionIcon = styled(VscRecord)`
	${commonIconStyles}
`;
const MultiModelIcon = styled(GrMultiple)`
	${commonIconStyles}
`;

const AnalyzedTag = styled.span`
	background-color: ${props => props.theme.colors.darkGreen};
	color: white;
	padding: 2px 5px;
	border-radius: 5px;
	font-size: 0.8rem;
	margin-left: 10px;
`;

const MapToolbar = ({ children, annotationMode }) => {
	const setSearchParams = useSetSearchParams();
	const [searchParams] = useSearchParams();

	const { currentUser, roleAdmin } = useAuth();

	const {
		project,
		pickedTask,
		tasks,
		toolBarVisible,
		mapObject,
		isDemo,
		dispatch,
	} = useProject();

	const [baseModels, setBaseModels] = useState(null);
	const [customModels, setCustomModels] = useState(null);

	const [showNewModelModal, setShowNewModelModal] = useState(false);

	const {
		data: tasksData,
		isLoading: tasksLoading,
		refetch: refetchTasks,
	} = useQuery({
		queryKey: ['tasks', currentUser?.uuid, currentUser?.active_org_id],
		queryFn: () => getAvailableModels(),
		enabled: !!currentUser?.uuid && !!project?.uuid,
		refetchOnWindowFocus: false,
	});

	const {
		data: modelsDetectedOn,
		isLoading: modelsDetectedOnLoading,
		refetch: refetchModelsDetectedOn,
	} = useQuery({
		queryKey: ['models_detected_on', project?.uuid],
		queryFn: () => getModelsDetectedOn(project?.uuid),
		enabled: !!project?.uuid,
		refetchOnWindowFocus: false,
	});

	const pickTask = task => {
		if (task) {
			// Reset the state
			dispatch({ type: 'setPickedModelClasses', payload: null });

			dispatch({ type: 'setFeatures', payload: [] });
			dispatch({
				type: 'setActiveSidebars',
				payload: [{ sidebarId: 'layerView' }],
			});

			// Set the task
			dispatch({ type: 'setTaskId', payload: task.model_uuid });
			dispatch({ type: 'setPickedTask', payload: task });
			setSearchParams({ model: task.model_uuid });

			const taskType = task.task_type || task.classes[0].task_type;

			if (taskType) {
				dispatch({
					type: 'setAnnotationType',
					payload: taskType,
				});
			}
		}
	};

	useEffect(() => {
		if (tasksLoading || modelsDetectedOnLoading) return;

		if (tasksData?.length > 0) {
			// Add task_type to each task object because it is not present in the API response
			// We need it like this for now
			const updatedTasks = tasksData.map(task => {
				const taskType = Array.isArray(task.classes)
					? task.classes[0].task_type
					: null;
				return {
					...task,
					task_type: taskType,
				};
			});

			dispatch({ type: 'setTasks', payload: updatedTasks });

			if (!pickedTask) {
				// If the model is in the URL, pick it
				const model = searchParams.get('model');
				const task = updatedTasks.find(
					task => task.model_uuid === model
				);

				if (task) {
					pickTask(task);
				} else if (
					(isDemo && project?.default_demo_model) ||
					(project?.default_demo_model && roleAdmin)
				) {
					// If it's a demo project, get and pick the default demo model
					const fetchDemoModel = async () => {
						try {
							const demoModel = await getDemoModel(
								project.default_demo_model
							);
							if (demoModel && demoModel.model_uuid) {
								pickTask(demoModel);
							}
						} catch (error) {
							console.error(
								'Error fetching default demo model',
								error
							);
						}
					};
					fetchDemoModel();
				}
			}

			//iterate the tasks and console log a warning if there are multiple entries with the same model_uuid
			const model_uuids = updatedTasks.map(task => task.model_uuid);
			const unique = [...new Set(model_uuids)];
			if (model_uuids.length !== unique.length) {
				console.warn(
					'There are multiple entries with the same model_uuid. This will break the model picker.'
				);
			}

			setBaseModels(updatedTasks.filter(task => task.basemodel));
			setCustomModels(updatedTasks.filter(task => !task.basemodel));
		} else {
			dispatch({ type: 'setTasks', payload: null });
			setBaseModels([]);
			setCustomModels([]);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tasksData, modelsDetectedOn]);

	if (!mapObject) return null;

	return (
		<>
			<Toolbar>
				{!annotationMode ? (
					<TaskPicker>
						<FilterLabel>Model:</FilterLabel>
						<Dropdown drop="up" id="modelDropDown">
							<Dropdown.Toggle
								variant="dark"
								drop="up"
								disabled={!toolBarVisible || !tasks || isDemo}>
								{!tasks ? (
									<DropText> Loading... </DropText>
								) : pickedTask ? (
									<DropText>
										{pickedTask.description}
									</DropText>
								) : (
									<DropText> Select a Model </DropText>
								)}
							</Dropdown.Toggle>
							<DropMenu variant="dark">
								<DropInner>
									<Dropdown.Item className="p-0">
										<NewModel
											addCustomTask={() => {
												setShowNewModelModal(true);
											}}
											customModels={customModels}
										/>
									</Dropdown.Item>
									<Dropdown.Divider />

									<DropdownItems
										baseModels={baseModels}
										customModels={customModels}
										pickTask={pickTask}
										pickedTask={pickedTask}
										refetchTasks={refetchTasks}
										modelsDetectedOn={modelsDetectedOn}
										refetchModelsDetectedOn={
											refetchModelsDetectedOn
										}
									/>
								</DropInner>
							</DropMenu>
						</Dropdown>
					</TaskPicker>
				) : null}
				<>{children}</>
				<Tooltip id="tooltip-toolbar-root" variant="light" />
			</Toolbar>
			{showNewModelModal && (
				<TaskModal
					setShowModal={setShowNewModelModal}
					refetchTasks={refetchTasks}
					pickTask={pickTask}
				/>
			)}
		</>
	);
};
export default MapToolbar;

const DropdownItems = ({
	baseModels,
	customModels,
	pickTask,
	pickedTask,
	refetchTasks,
	modelsDetectedOn,
	refetchModelsDetectedOn,
}) => {
	const { addToast } = useToast();
	const { tierTrial } = useAuth();

	const [searchValue, setSearchValue] = useState('');
	const handleSearch = e => {
		e.preventDefault();
		setSearchValue(e.target.value);
	};

	const deleteMutation = useMutation({
		mutationFn: task => {
			if (task.multimodel) {
				return deleteMultiModel(task);
			}

			return deleteModel(task.model_uuid);
		},
		onSuccess: () => {
			refetchTasks();
			refetchModelsDetectedOn();
			addToast({
				id: `model-delete-${new Date().getTime()}`,
				title: 'Model deleted successfully!',
				bg: 'success',
			});
		},
		onError: (error, task) => {
			console.error(error);
			const errorId = task.multimodel ? task.model_uuid : task.task_uuid;
			addToast({
				id: `model-delete-error-${errorId}`,
				bg: 'danger',
				title: 'Error: model could not be deleted!',
				message: error.response?.data?.detail || null,
			});
		},
	});

	const trashTask = async task => {
		if (tierTrial) {
			alert('You cannot delete a model as a trial user');
			return;
		}

		//@TODO: Add a confirmation modal
		if (
			window.confirm(
				`Are you sure you want to delete ${task.description}?`
			)
		) {
			deleteMutation.mutate(task);
		}
	};

	const taskTypeIcon = task => {
		if (task.multimodel) {
			return (
				<span
					data-tooltip-id="tooltip-toolbar-root"
					data-tooltip-content="Multi model"
					data-tooltip-place="left">
					<MultiModelIcon />
				</span>
			);
		} else if (task.task_type === 'object_detection') {
			return (
				<span
					data-tooltip-id="tooltip-toolbar-root"
					data-tooltip-content="Object detection model"
					data-tooltip-place="left">
					<ObjectDetectionIcon />
				</span>
			);
		}
		return (
			<span
				data-tooltip-id="tooltip-toolbar-root"
				data-tooltip-content="Segmentation model"
				data-tooltip-place="left">
				<SegmentationIcon />
			</span>
		);
	};

	const taskDescription = task => {
		return (
			<>
				{taskTypeIcon(task)}
				{task.description}
				{
					// If task.model_uuid is in modelsDetectedOn, show a small icon to indicate that the model contains results
					modelsDetectedOn &&
						modelsDetectedOn.includes(task.model_uuid) && (
							<AnalyzedTag>Analyzed</AnalyzedTag>
						)
				}
			</>
		);
	};

	return (
		<>
			{baseModels && (
				<>
					<DropdownHeader>
						<span>Base Models</span>
						<span
							data-tooltip-id="tooltip-toolbar-root"
							data-tooltip-content="Base models are pre-generated by Biodrone and cannot be modified or removed"
							data-tooltip-place="left">
							<FiHelpCircle />
						</span>
					</DropdownHeader>

					{baseModels?.map(task => (
						<Dropdown.Item
							as="button"
							type="button"
							id={task.model_uuid}
							key={task.model_uuid}
							onClick={() => pickTask(task)}
							disabled={
								pickedTask &&
								pickedTask.model_uuid === task.model_uuid
							}
							className={
								task.description
									.toLowerCase()
									.includes(searchValue.toLowerCase())
									? ''
									: 'd-none'
							}>
							<Row>
								<span>{taskDescription(task)}</span>
								<LockIcon className="ms-2" />
							</Row>
						</Dropdown.Item>
					))}

					<Dropdown.Divider />
				</>
			)}

			<DropdownHeader>
				<span>Customizable Models</span>
				<span
					data-tooltip-id="tooltip-toolbar-root"
					data-tooltip-content="Customizable models can be trained to detect any object of interest."
					data-tooltip-place="left">
					<FiHelpCircle />
				</span>
			</DropdownHeader>

			{customModels?.map(task => {
				return (
					<Dropdown.Item
						as="div"
						id={task.model_uuid}
						key={task.model_uuid}
						className={
							task.description
								.toLowerCase()
								.includes(searchValue.toLowerCase())
								? ''
								: 'd-none'
						}>
						<Row>
							<DropDownButton
								type="button"
								className="dropdown-item"
								onClick={() => pickTask(task)}
								disabled={
									pickedTask &&
									pickedTask.model_uuid === task.model_uuid
								}>
								{taskDescription(task)}
							</DropDownButton>

							<DropDownButton
								type="button"
								onClick={() => {
									trashTask(task);
								}}
								disabled={
									pickedTask &&
									pickedTask.model_uuid === task.model_uuid
								}>
								<DeleteIcon className="ms-2" />
							</DropDownButton>
						</Row>
					</Dropdown.Item>
				);
			})}

			<SearchInput>
				<Input
					type="search"
					placeholder="Search"
					value={searchValue}
					onChange={handleSearch}
					autoFocus
				/>
			</SearchInput>
		</>
	);
};
