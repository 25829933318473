// a context that holds the state of the project and the functions that change the state

import { createContext, useContext, useReducer } from "react";
import { get as getProjection } from "ol/proj.js";

const ProjectContext = createContext(null);

// Constants as in the backend. Is used to set the project mode
const ImageMode = {
    GENERATE_ORTHOPHOTO: "generate_orthophoto",
	UPLOAD_ORTHOPHOTO: "upload_orthophoto",
    SINGLE_IMAGE: "single_image"
};
// Constants as in the frontend for the project mode
export const ProjectMode = {
	ORTHOPHOTO: "orthophoto",
	SINGLE_IMAGE: "single_image"
};

// Constants for the annotation mode
export const AnnotationTypes = {
	OBJECT_DETECTION: 'object_detection',
	SEGMENTATION: 'segmentation',
};

// Default color options
const colorOptions = [
	{ r: 0, g: 225, b:255, a: 1 },
	{ r: 237, g: 120, b: 203, a: 1 },
	{ r: 244, g: 109, b: 67, a: 1 },
	{ r: 148, g: 137, b: 255, a: 1 },
	{ r: 114, g: 247, b: 255, a: 1 },
	{ r: 158, g: 1, b: 66, a: 1 },
	{ r: 230, g: 245, b: 152, a: 1 },
	{ r: 171, g: 221, b: 164, a: 1 },
	{ r: 255, g: 255, b: 255, a: 1 },
	{ r: 182, g: 138, b: 96, a: 1 },
];

// Available draw tools grouped in annotation types
const availableAnnotationDrawTools = {
	object_detection: ['Box', 'Circle'],
	segmentation: ['Polygon', 'Freehand'],
};

const initialState = {
    project: null,
	projectMode: null,
	isDemo: false,

    features: [],
	colorScheme: colorOptions.reduce((acc, color, index) => {
		acc[index] = { color, visible: true };
		return acc;
	}, {}),
	trainingDataColorScheme: {},

	singleImageFeatures: [],
	selectedSingleImage: null,

    mapObject: null,
	mapTooltip: null,
	mapTooltipContent: null,

    pointLayer: null,
    hexagonLayer: null,
    polygonLayer: null,

	singleImageLayer: null,
	singleImagePolygonVectorSource: null,
	singleImagePolygonLayer: null,
	singlePhotosLayer: null,
	filteredImages: null,

    taskId: null,
    pickedTask: null,
	pickedModelClasses: null,
    tasks: null,
    dialogue: null,
    toolBarVisible: true,

    hexagonInfo: null,
    hexagonSize: 1000,
	selectedHexagon: null,

    extent: null,

	annotationMode: null,
	annotationSidebarData: null,
	annotationType: null,
	annotationDrawTool: null,

	confirmModalContent: null,

	activeSidebars: [{sidebarId: 'layerView'}],
};

const actions = {
    setFeatures: (state, payload) => ({ ...state, features: payload }),
	setSingleImageFeatures: (state, payload) => ({ ...state, singleImageFeatures: payload }),
	setSelectedSingleImage: (state, payload) => ({ ...state, selectedSingleImage: payload }),
    setColorScheme: (state, payload) => ({ ...state, colorScheme: payload }),
	setTrainingDataColorScheme: (state, payload) => ({ ...state, trainingDataColorScheme: payload }),
    setMapObject: (state, payload) => ({ ...state, mapObject: payload }),
	setMapTooltip: (state, payload) => ({ ...state, mapTooltip: payload }),
	setMapTooltipContent: (state, payload) => ({ ...state, mapTooltipContent: payload }),
    setPointLayer: (state, payload) => ({ ...state, pointLayer: payload }),
    setHexagonLayer: (state, payload) => ({ ...state, hexagonLayer: payload }),
    setPolygonLayer: (state, payload) => ({ ...state, polygonLayer: payload }),
	setSingleImageLayer: (state, payload) => ({ ...state, singleImageLayer: payload }),
	setSingleImagePolygonVectorSource: (state, payload) => ({ ...state, singleImagePolygonVectorSource: payload }),
	setSingleImagePolygonLayer: (state, payload) => ({ ...state, singleImagePolygonLayer: payload }),
	setSinglePhotosLayer: (state, payload) => ({ ...state, singlePhotosLayer: payload }),
	setFilteredImages: (state, payload) => ({ ...state, filteredImages: payload }),
    setTaskId: (state, payload) => ({ ...state, taskId: payload }),
    setPickedTask: (state, payload) => ({ ...state, pickedTask: payload }),
	setPickedModelClasses: (state, payload) => ({ ...state, pickedModelClasses: payload }),
    setTasks: (state, payload) => ({ ...state, tasks: payload }),
	setAnnotationDrawTool: (state, payload) => ({ ...state, annotationDrawTool: payload }),
    setDialogue: (state, payload) => ({ ...state, dialogue: payload }),
    setToolBarVisible: (state, payload) => ({ ...state, toolBarVisible: payload }),
    setHexagonInfo: (state, payload) => ({ ...state, hexagonInfo: payload }),
    setHexagonSize: (state, payload) => ({ ...state, hexagonSize: payload }),
	setSelectedHexagon: (state, payload) => ({ ...state, selectedHexagon: payload }),
    setExtent: (state, payload) => ({ ...state, extent: payload }),
    setIsDemo: (state, payload) => ({ ...state, isDemo: payload }),
	setAnnotationMode: (state, payload) => ({ ...state, annotationMode: payload }),
	setAnnotationSidebarData: (state, payload) => ({ ...state, annotationSidebarData: payload }),
	setConfirmModalContent: (state, payload) => ({ ...state, confirmModalContent: payload }),
	setActiveSidebars: (state, payload) => ({ ...state, activeSidebars: payload }),
};

const setProjectMode = (imageMode) => {
    switch (imageMode) {
        case ImageMode.GENERATE_ORTHOPHOTO:
        case ImageMode.UPLOAD_ORTHOPHOTO:
            return ProjectMode.ORTHOPHOTO;
        case ImageMode.SINGLE_IMAGE:
            return ProjectMode.SINGLE_IMAGE;
        default:
            // Default is orthophoto
            return ProjectMode.ORTHOPHOTO;
    }
};

function projectReducer(state, action) {
    // Handle different action types using a switch statement
    switch (action.type) {
        case "setProject":
            // If the payload is null, reset the project and projectMode to null
            if (action.payload === null) {
                return { ...state, project: null, projectMode: null };
            }
            // Determine the project mode based on the image mode in the payload
            const projectMode = setProjectMode(action.payload.image_mode);
            // Update the state with the new project and project mode
            return { ...state, project: action.payload, projectMode };

        case "setAnnotationType":
            try {
                // Get the default draw tool for the given annotation type
                const defaultDrawTool = availableAnnotationDrawTools[action.payload][0];
                // Update the state with the new annotation type and draw tool
                return { ...state, annotationType: action.payload, annotationDrawTool: defaultDrawTool };
            } catch (error) {
                // Log an error if there is an issue setting the annotation type
                console.error("Error setting annotation type:", error);
                // Update the state with the new annotation type without changing the draw tool
                return { ...state, annotationType: action.payload };
            }

        case "resetState":
            // Reset the state to the initial state
            return initialState;

        default:
            // Handle custom actions defined in the actions object
            const handler = actions[action.type];
            if (!handler) {
                // Throw an error if the action type is unknown
                throw new Error(`Unknown action: ${action.type}`);
            }
            // Call the custom handler with the current state and action payload
            return handler(state, action.payload);
    }
}

export function ProjectProvider({ children }) {
	// How to use:
	// `dispatch({ type: 'setProject', payload: newProject }).`
    const [state, dispatch] = useReducer(projectReducer, initialState);

	const setActiveSidebar = ({sidebarId, parentId}) => {
		const sidebarElement = {sidebarId, parentId};

		// Remove any other active sidebar that is of same parent
		const newActiveSidebars = state.activeSidebars;

		// Check if sidebarId already exists
		const doesExist = newActiveSidebars.some(sidebar => sidebar.sidebarId === sidebarId);

		// If sidebarId does not exist, add the new sidebarElement
		if (!doesExist) {
			newActiveSidebars.push(sidebarElement);
			dispatch({ type: 'setActiveSidebars', payload: newActiveSidebars });
		}
	};

	const removeFromActiveSidebars = (sidebarId) => {
		dispatch({
			type: 'setActiveSidebars',
			payload: state.activeSidebars.filter(sidebar => sidebar.sidebarId !== sidebarId)
		});
	};

    const defaultProjection = getProjection('EPSG:3857');

    return (
        <ProjectContext.Provider value={{
			...state,
			dispatch,
			colorOptions,
			availableAnnotationDrawTools,
			defaultProjection,
			setActiveSidebar,
			removeFromActiveSidebars
		}}>
            {children}
        </ProjectContext.Provider>
    );
};

export function useProject() {
	const context = useContext(ProjectContext);
	if (!context) {
		throw new Error("useProject must be used within a ProjectProvider");
	}
	return context;
}
